<template>
  <div v-swiper:ProjectSlider="settings">
    <div class="swiper-wrapper relaitve">
      <slot />
    </div>
    <div class="relative text-center top-0 lg:hidden project-pagination fibre-pagination swiper-pagination"></div>
  </div>
</template>
<script>
export default {
  name: 'ProjectSlider',
  data () {
    return {
      settings: {
        slidesPerView: 1,
        loop: false,
        spaceBetween: 0,
        noSwiping: false,
        autoplay: {
          delay: 3000,
        },
        pagination: {
          clickable: true,
          el: '.project-pagination',
        },
        allowTouchMove: true,
        breakpoints: {
          600: {
            slidesPerView: 2,
            spaceBetween: 16,
          }
        }
      }
    }
  }
}
</script>
